<template>
  <div
    class="global-auto-scroll-content hide-scrollbar"
    ref="globalAutoScrollWrap"
  >
    <template v-if="content">
      <marquee v-if="isScroll" v-bind="scrollConfig">
        <div class="content" v-html="content"></div>
      </marquee>
      <div
        v-else
        ref="globalAutoScrollContent"
        class="content"
        v-html="content"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    scrollConfig: {
      type: Object,
      default() {
        return {
          direction: "up",
          scrollamount: window.g.contentScrollSpeed || "3",
        };
      },
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isScroll: false,
    };
  },
  watch: {
    content() {
      if (this.isInit) {
        this.isScroll = false;
      }

      this.init();
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        const imgEl = $(this.$refs.globalAutoScrollContent).find("img");
        const imgElLength = imgEl.length;
        let loadCount = 0;
        console.log(
          "🚀 ~ file: GlobalScrollContent.vue:67 ~ init ~ imgEl:",
          imgEl,
          imgEl.length
        );

        if (imgElLength > 0) {
          // 如果有图片
          // 图片加载完成后再计算高度
          $(imgEl).on("load", () => {
            loadCount++;

            if (loadCount == imgElLength) {
              this.setScroll();
            }
          });
        } else {
          // 没有图片
          this.setScroll();
        }
      });
    },
    setScroll() {
      this.$nextTick(() => {
        const wrapHeight = this.$refs.globalAutoScrollWrap.offsetHeight;
        const contentHeight = this.$refs.globalAutoScrollContent.offsetHeight;

        if (contentHeight > wrapHeight) {
          this.isScroll = true;
        } else {
          this.isScroll = false;
        }

        this.isInit = true;
      });
    },
  },
};
</script>

<style lang="scss">
.global-auto-scroll-content {
  height: 100%;

  marquee {
    height: 100%;
  }
  .content {
    img {
      width: 100% !important;
    }
  }
}
</style>
