<template>
  <el-dialog
    class="global-dialog"
    :width="width"
    :visible="show"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <div class="title-view">
      <!-- <img
        class="custom-title-block-icon"
        src="@/assets/images/dialog/title_block@2x.png"
        alt=""
      />
      <img
        class="custom-title-circle-icon"
        src="@/assets/images/theme1/title_icon@2x.png"
        alt=""
      /> -->
      <span class="title">{{ title }}</span>
      <!-- <img
        class="global-dialog-close-icon"
        src="@/assets/images/dialog/icon_close@2x.png"
        alt=""
        @click="close"
      /> -->
    </div>
    <div class="content">
      <slot></slot>
    </div>

    <span slot="footer" class="dialog-footer" v-if="footerShow">
      <el-button size="mini" class="cancel" @click="close">取 消</el-button>
      <el-button size="mini" class="comfirm" @click="comfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '50%',
    },
    title: {
      type: String,
      default: '提示提示',
    },
    show: {
      type: true,
      default: Boolean,
    },
    footerShow: {
      type: false,
      default: Boolean,
    },
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('closeDialog')
    },
    comfirm() {
      this.$emit('comfirmDialog')
    },
  },
}
</script>

<style lang="scss">
.global-dialog {
  .el-dialog {
    background: transparent;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__headerbtn {
    display: none;
  }

  .title-view {
    position: relative;
    height: px2vh(44);
    line-height: px2vh(44);
    background: #0e1521;
    background-size: 100% 100%;
    padding-left: px2vw(70);
    @include font4vh(#fff, 18, 700);
    .custom-title-block-icon {
      position: absolute;
      left: px2vh(20);
      top: 50%;
      height: px2vh(22);
      transform: translateY(-50%);
    }
    .custom-title-circle-icon {
      position: absolute;
      left: px2vh(30);
      top: 50%;
      height: px2vh(40);
      transform: translateY(-50%);
    }
    .global-dialog-close-icon {
      position: absolute;
      right: px2vh(20);
      top: 50%;
      height: px2vh(20);
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .content {
    padding: px2vh(20) px2vh(40);
    background: #0e1521;
    max-height: px2vh(700);
    overflow: auto;
  }
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    background: #081f22;
  }

  .cancel {
    background: #00e4ff;
    border-color: #002438;
  }

  .comfirm {
    background: #002438;
    border-color: #002438;
  }
}
</style>
