import axios from "axios";
import { Notify } from 'vant';
import router from "@/router/index";

const instance = axios.create({
    headers: {
        "Cache-Control": "no-cache",
    },
});

let baseUrl = window.g.baseUrl;
if (process.env.NODE_ENV === "development") {
    baseUrl = "proxy";
}

// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        let token = $cookies.get('token')
        // 在发送请求之前做些什么
        if (window.g.openCancelRequest && window._axiosQueue) {
            config.cancelToken = new axios.CancelToken(function (cancel) {
                window._axiosQueue.push({ cancel });
            });
        }
        config.headers.Authorization = token;
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 如果响应的状态码是 401
        if (response.data.code === 401) {
            Notify({ type: 'warning', message: '未授权，请重新登录' });
            // 可以选择跳转到登录页面
            $cookies.remove('token')
            localStorage.removeItem('userInfo')
            router.push("/Login");
            return Promise.reject('Unauthorized');
        }
        // 对响应数据做点什么
        if (response.data.code != 200) {
            Notify({ type: 'danger', message: response.data.msg, });
            return Promise.reject(response.data);
        }
        return response.data;
    },
    function (error) {
        if (error.message.indexOf("401") > -1) {
            router.push("/");
        }
        if (error.message !== "请求取消") {
            Notify({ type: 'danger', message: error.message, });
            // 对响应错误做点什么
            return Promise.reject(error);
        } else if (error.message == "请求取消") {
            return Promise.reject(error);
        }
    }
);

export function get(url, params) {
    return new Promise((resolve, reject) => {
        instance
            .get(baseUrl + url, { params })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance
            .post(baseUrl + url, data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
