<template>
  <div id="app">
    <div class="home-header-box">
      <div class="home-header-lr">
        <img
          src="@/assets/logo.png"
          alt=""
          style="width: 142px; height: 36px; margin-left: 10px"
        />
      </div>
      <div class="home-header-lr">
        <img
          src="@/assets/login-user.png"
          alt=""
          class="login-user"
          @click="goLogin"
          v-if="!isToken"
        />
        <img
          src="@/assets/login-user-actived.png"
          @click="goUser"
          alt=""
          v-if="isToken"
          class="login-user"
        />
        <img
          src="@/assets/menu-icon.png"
          alt=""
          style="padding: 0 12px"
          class="login-user"
          @click="goHome"
        />
      </div>
    </div>
    <router-view />
    <van-action-sheet v-model="isVoicedialogue" style="height: 100%">
      <Voicedialogue
        v-if="isVoicedialogue"
        @closeVoicedialogue="closeVoicedialogue"
        :params="voicedialogueParams"
      />
    </van-action-sheet>
    <!-- <transition
      name="slide"
      enter-active-class="slideInLeft"
      leave-active-class="slideOutLeft"
      mode="out-in"
    >
 
    </transition> -->
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import autofit from "autofit.js";
import Voicedialogue from "./views/Voicedialogue.vue";
import { login, getInfo } from "@/api/home.js";

export default {
  components: {
    Voicedialogue,
  },
  data() {
    return {
      loginForm: {
        userName: "admin",
        password: "admin123",
      },
      isVoicedialogue: false,
      voicedialogueParams: {}, //对话框参数
    };
  },
  created() {
    this.$bus.$on("openVoicedialogue", this.openVoicedialogue);
    this.$bus.$on("closeVoicedialogue", this.closeVoicedialogue);
    // login(this.loginForm)
    //   .then((res) => {
    //     if (res.code == 200) {
    //       //   Notify({ type: "success", message: "登录成功" });
    //       this.$cookies.set("token", res.token);
    //       this.$store.state.isToken = true;
    //       getInfo().then((res) => {
    //         if (res.code == 200) {
    //           localStorage.setItem("userInfo", JSON.stringify(res.user));
    //           this.$router.push({ path: "/" });
    //         }
    //       });
    //     } else {
    //       Notify({ type: "danger", message: res.msg });
    //     }
    //   })
    //   .finally(() => {});
  },
  mounted() {
    // autofit.init({
    //   dh: 930,
    //   dw: 430,
    //   el: "#app",
    //   resize: true,
    // });
  },
  computed: {
    isToken() {
      return this.$store.state.isToken;
    },
  },
  methods: {
    goLogin() {
      if (this.$route.path !== "/Login") {
        this.$router.push("/Login");
      }
    },
    goUser() {
      if (this.$route.path !== "/User") {
        this.$router.push("/User");
      }
    },
    goHome() {
      this.$bus.$emit("currentComponent", null);
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },
    openVoicedialogue(params) {
      this.isVoicedialogue = true;
      this.voicedialogueParams = params;
    },
    closeVoicedialogue() {
      this.isVoicedialogue = false;
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
.home-header-box {
  height: 72px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #d8d8d8;
  max-width: 640px;
  margin: 0 auto;
  .home-header-lr {
    height: 100%;
    display: flex;
    align-items: center;
    .login-user {
      width: 32px;
      height: 32px;
    }
  }
}
.my-select {
  .el-select-dropdown__item.selected::after {
    display: none !important;
  }
}
</style>
