<template>
  <div class="input-add-com">
    <el-row
      class="input-add-row"
      v-bind="$attrs.rowItemProps"
      v-for="(row, rowIndex) in list"
      :key="rowIndex"
    >
      <el-col
        v-bind="col.colProps"
        v-for="(col, colIndex) in children"
        :key="colIndex"
      >
        <el-input
          v-if="col.type == 'el-input'"
          v-model="row[col.key]"
          v-bind="col.itemProps || {}"
          v-on="col.itemEvents || {}"
        ></el-input>
        <el-select
          v-else-if="col.type === 'el-select'"
          v-model="row[col.key]"
          v-bind="col.itemProps || {}"
          v-on="col.itemEvents || {}"
        >
          <el-option
            :label="option[$attrs.labelKey || 'label']"
            :value="option[$attrs.valueKey || 'value']"
            v-for="option in $attrs.options"
            :key="option[$attrs.valueKey || 'value']"
          ></el-option>
        </el-select>
      </el-col>
      <div class="input-hanlde-btns">
        <el-button
          icon="el-icon-plus"
          size="mini"
          @click="add"
          v-show="list.length < $attrs.options.length"
        ></el-button>
        <el-button
          icon="el-icon-minus"
          size="mini"
          v-show="rowIndex > 0"
          @click="remove(rowIndex)"
        ></el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    children: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$attrs.defaultData) {
        this.list = JSON.parse(JSON.stringify(this.$attrs.defaultData));
      } else {
        this.list.push(this.getItem());
      }

      this.$set(this.form, this.$attrs.componentkey, this.list);
    },
    add() {
      this.list.push(this.getItem());
    },
    remove(index) {
      this.list.splice(index, 1);
    },
    getItem() {
      const item = {};
      this.children.forEach((el) => {
        item[el.key] = "";
      });

      return item;
    },
  },
};
</script>

<style lang="scss">
.input-add-com {
  width: 100%;
  .input-add-row {
    @include flex(row, normal, center);
    margin-bottom: px2vh(20);
  }

  .input-hanlde-btns {
    .el-button {
      margin-left: px2vh(20);
      background-color: transparent;
      border-color: rgba(203, 224, 255, 0.5);
    }
  }
}
</style>
