<template>
  <transition mode="out-in" v-on:leave="leave">
    <slot />
  </transition>
</template>

<script>
const animateCSS = (element, animation, removeAnimation) =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const node = element
    node.classList.remove(...removeAnimation)
    node.classList.add(...animation)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation()
      // node.classList.remove(...animation)
      node.classList.remove(...animation)
      resolve('Animation ended')
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true })
  })
export default {
  props: {
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      animateClass: ''
    }
  },
  created() {
    this.animateClass = this.option.enterClass
  },
  methods: {
    sleep(time) {
      return new Promise((resovle) => setTimeout(resovle, time))
    },
    // 当与 CSS 结合使用时
    // 回调函数 done 是可选的
    leave: function (el, done) {
      const animatedDoms = Array.from(
        document.querySelectorAll('.directive__animate__animated')
      )

      const promises = animatedDoms.map((an) =>
        animateCSS(
          an,
          JSON.parse(an.dataset.leaveClass),
          JSON.parse(an.dataset.enterClass)
        )
      )

      Promise.all(promises).then((res) => {
        done()
      })
    }
  }
}
</script>

<style></style>
