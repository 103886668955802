import { get, post } from "../services/http";
export function login(params = {}) {
    return post(`/login`, params);
}
export function getFirstStyleByTime(params = {}) {
    return get(`/traeldecision/firststyle/getFirstStyleByTime`, params);
}
export function getFirstStyleByStartAndEndTime(params = {}) {
    return get(`/traeldecision/firststyle/getFirstStyleByStartAndEndTime`, params);
}
export function chathistory(params = {}) {
    return post(`/chathistory/chathistory/chat`, params);
}

//奇门星宿ai接口
export function chat_single_relationship(params = {}) {
    return post(`/chathistory/chathistory/chat_single_relationship`, params);
}
//语音回复
export function chat_audio(params = {}) {
    return post(`/chathistory/chathistory/chatAudio`, params);
}
//穿出好运_文本回复
export function chat_aiDressClothe(params = {}) {
    return post(`/chathistory/chathistory/chat_aiDressClothe`, params);
}

//秒断奇门_文本回复
export function chat_aiSecondsOff(params = {}) {
    return post(`/chathistory/chathistory/chat_aiSecondsOff`, params);
}

//手机号_文本回复
export function chat_aiPhoneDefuse(params = {}) {
    return post(`/chathistory/chathistory/chat_aiPhoneDefuse`, params);
}


//八字_文本回复
export function chat_aiEightWord(params = {}) {
    return post(`/chathistory/chathistory/chat_aiEightWord`, params);
}
//根据方向及门查出对应结果
export function doorDir(params = {}) {
    return post(`/system/door/doorDir`, params);
}