const Home = () => import('@/views/Home/Index');
const User = () => import('@/views/User/Index.vue');
const Login = () => import('@/views/Login/Index');
const Register = () => import('@/views/Register/Index');
const Vip = () => import('@/views/BuyVip/Index.vue');
const BuyList = () => import('@/views/BuyList/Index.vue');
const Pay = () => import('@/views/Pay/Index.vue');
const UserEdit = () => import('@/views/UserEdit/Index.vue');
const Cxj = () => import('@/views/Home/components/Cxj.vue');
const Qmxx = () => import('@/views/Home/components/Qmxx.vue');
const Yszx = () => import('@/views/Home/components/Yszx.vue');
const Bz = () => import('@/views/Home/components/Bz.vue');
const Mdqm = () => import('@/views/Home/components/Mdqm.vue');
const Sjh = () => import('@/views/Home/components/Sjh.vue');
const BzDetails = () => import('@/views/Home/components/BzDetails.vue');
const Clause = () => import('@/views/Clause/index.vue');
const PrivacyPolicy = () => import('@/views/PrivacyPolicy/index.vue');
const GetAuthCode = () => import('@/views/GetAuthCode/index.vue');
//Voice dialogue
export default [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/user",
        component: User,
    },
    {
        path: "/vip",
        component: Vip,
    },
    {
        path: "/buyList",
        component: BuyList,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/register", // 注册
        component: Register,
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/pay",
        component: Pay,
    },
    {
        path: "/userEdit",
        component: UserEdit,
    },
    {
        path: "/Cxj",
        name: "Cxj",
        component: Cxj,
    },
    {
        path: "/Qmxx",
        name: "Qmxx",
        component: Qmxx,
    },
    {
        path: "/Yszx",
        name: "Yszx",
        component: Yszx,
    },
    {
        path: "/Bz",
        name: "Bz",
        component: Bz,
    },
    {
        path: "/BzDetails",
        name: "BzDetails",
        component: BzDetails,
    },
    {
        path: "/Mdqm",
        name: "Mdqm",
        component: Mdqm,
    },
    {
        path: "/Sjh",
        name: "Sjh",
        component: Sjh,
    },
    {
        path: "/Clause",
        name: "Clause",
        component: Clause,
    },
    {
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/GetAuthCode",
        name: "GetAuthCode",
        component: GetAuthCode,
    }
];
