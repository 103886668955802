// import Vue from 'vue'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "./utils/rem";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./components/global/index";
import "./styles/index.scss";
import "vue2-animate/src/sass/vue2-animate.scss";
import "animate.css";
import console from "@/utils/dateLog";
import utils from "@/utils/index";
import fontSize from "./utils/fontSize";
import "./directive/index";
import i18n from "@/i18n";
import VueCookies from 'vue-cookies'
import Vant from 'vant';
import 'vant/lib/index.css';
import { EventBus } from '@/utils/bus';
import '@vant/touch-emulator'
import Vconsole from 'vconsole';
// Object.assign(window.console, console);
// 设置默认主题
store.commit("setTheme", "theme-default");

moment.locale("zh-cn");
// Vue.use(ElementUI);
Vue.use(VueCookies)
Vue.use(Vant);
// Vue.use(new Vconsole())
Vue.prototype.$Moment = moment;
Vue.prototype.$FontSize = fontSize;
Vue.prototype.$utils = utils;
Vue.prototype.$baseUrl =
    process.env.NODE_ENV === "development" ? "proxy" : window.g.baseUrl;

Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
