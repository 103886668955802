<template>
  <div
    class="global-table flex flex-col global-auto-scroll-table"
    ref="globalAutoScrollTable"
  >
    <el-table
      v-if="tableHeight"
      class="global-table-container flex-1 hidden-tbody"
      ref="table"
      :data="tableData"
      height="100%"
      v-bind="tableConfig"
      @selection-change="onSelectionChange"
      @sort-change="onSortChange"
      @row-click="onRowClick"
    >
      <template v-for="item in tableColumn">
        <el-table-column
          :show-overflow-tooltip="item.showTooltip"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-if="!item.type"
          :key="`${item.prop}_${item.label}`"
          v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
          :sortable="item.sortable || false"
        >
          <template slot-scope="scope">
            <template v-if="item.prop === 'custom-column'">
              <slot
                :name="item.name"
                :row="scope.row"
                :index="scope.$index"
              ></slot>
            </template>
            <template v-else>
              {{ scope.row[item.prop] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :type="item.type"
          :label="item.label || ''"
          :width="item.width"
          :key="item.prop"
          v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
        >
        </el-table-column>
      </template>
    </el-table>
    <vue-seamless-scroll
      v-if="tableBodyHeight"
      ref="seamless"
      :data="tableData"
      class="seamless-warp"
      :style="{ width: '100%', height: tableBodyHeight + 'px' }"
      :class-option="classOption"
    >
      <el-table
        ref="table"
        class="global-table-container flex-1"
        :data="tableData"
        height="100%"
        v-bind="tableConfig"
        :show-header="false"
        @selection-change="onSelectionChange"
        @sort-change="onSortChange"
        @row-click="onRowClick"
      >
        <template v-for="item in tableColumn">
          <el-table-column
            :show-overflow-tooltip="item.showTooltip"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-if="!item.type"
            :key="`${item.prop}_${item.label}`"
            v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
            :sortable="item.sortable || false"
          >
            <template slot-scope="scope">
              <template v-if="item.prop === 'custom-column'">
                <slot
                  :name="item.name"
                  :row="scope.row"
                  :index="scope.$index"
                ></slot>
              </template>
              <template v-else>
                {{ scope.row[item.prop] }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :type="item.type"
            :label="item.label || ''"
            :width="item.width"
            :key="item.prop"
            v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
          >
          </el-table-column>
        </template>
      </el-table>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import VueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    VueSeamlessScroll,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    tableColumn: {
      type: Array,
      default() {
        return [];
      },
    },
    tableConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableHeight: 0,
      tableBodyHeight: 0,
      tableRowHeight: this.$FontSize(0.36),
      isScroll: false,
      intervalTime: 1000,
      autoPlay: false,
      delayTime: 2000,
    };
  },
  computed: {
    classOption() {
      return {
        autoPlay: this.autoPlay,
        step: window.g.tableScrollSpeed, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        //   openWatch: true, // 开启数据实时监控刷新dom
        //   singleHeight: this.tableRowHeight, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleHeight:
        //   Math.floor(this.tableBodyHeight / this.tableRowHeight) *
        //   this.tableRowHeight, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: this.delayTime, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  watch: {
    tableData: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$nextTick(() => {
            this.initTableHeight();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.tableHeight = this.$refs.globalAutoScrollTable.offsetHeight;
  },
  methods: {
    resize() {
      this.tableHeight = 0;
      this.tableBodyHeight = 0;
      this.isScroll = false;
      this.autoPlay = false;
      this.$nextTick(() => {
        this.tableHeight = this.$refs.globalAutoScrollTable.offsetHeight;

        this.$nextTick(() => {
          // this.tableRowHeight = this.$FontSize(0.36);
          this.initTableHeight();
        });
      });
    },
    getRowHeight() {
      const bodyEl = this.$refs.table.$children.find(
        (item) => item.$el.className === "el-table__body"
      );

      const rowEl = bodyEl.$children.find(
        (item) => item.$el.className === "el-table__row"
      );
      this.tableRowHeight = rowEl.$el.offsetHeight;
    },
    initTableHeight() {
      if (this.tableData && this.tableData.length == 0) return;
      const headerEl = this.$refs.table.$children.find(
        (item) => item.$el.className === "el-table__header"
      );

      this.getRowHeight();
      this.tableBodyHeight = this.tableHeight - headerEl.$el.offsetHeight;

      this.getIsScroll();

      setTimeout(() => {
        this.autoPlay = this.isScroll;
      }, this.delayTime);
    },
    getIsScroll() {
      this.$nextTick(() => {
        const tbodyHeight = this.$refs.seamless.$children[0].$el.offsetHeight;

        this.isScroll = tbodyHeight > this.tableBodyHeight;
      });
    },
    onSelectionChange(selection) {
      this.$emit("onSelectionChange", selection);
    },
    onSortChange({ column, prop, order }) {
      this.$emit("onSortChange", prop, order);
    },
    onRowClick(row, column, event) {
      this.$emit("onRowClick", row, column, event);
    },
  },
};
</script>

<style lang="scss">
.global-auto-scroll-table {
  height: 100%;
  overflow: hidden;

  .hidden-tbody {
    .el-table__body-wrapper {
      display: none;
    }
  }

  .seamless-warp {
    overflow: hidden;
  }
}
</style>
