import moment from 'moment'

const { log, warn, error, info } = window.console
const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const dateTime = () => moment().format(dateFormat)

export default {
  log (...msg) {
    log(dateTime(), ...msg)
  },
  warn (...msg) {
    warn(dateTime(), ...msg)
  },
  error (...msg) {
    error(dateTime(), ...msg)
  },
  info (...msg) {
    info(dateTime(), ...msg)
  }
}
